/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
  'https://cms.cognijiwa.ai/assets/8af0fb24-a1bf-4230-8b8a-13b60e1b2bb5.png',
  'https://cms.cognijiwa.ai/assets/3dc69d5c-99cc-4a26-834e-26dd2d9bbd27.png',
  'https://cms.cognijiwa.ai/assets/5d60e0c4-3bee-4cb2-bfc0-7897d8154f03.png',
  'https://cms.cognijiwa.ai/assets/433399a0-f8f2-4262-829a-0f2e9e3d17ca.png',
  'https://cms.cognijiwa.ai/assets/8d72fe7f-ccf1-40db-a7e9-2f1dcbcb4368.png',
  'https://cms.cognijiwa.ai/assets/8001d092-d476-4867-8022-57fe53c67ed7.png',
];

const Partners = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Trusted by you favorite companies
            </Typography>
            <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
              We are registered as a distributor with AMFI,
              <br />
              investment advisor with SEBI and platform partners with BSE.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box display="flex" flexWrap="wrap" justifyContent={'flex-start'}>
              {mock.map((item, i) => (
                <Box maxWidth={90} marginTop={2} marginRight={4} key={i}>
                  <Box
                    component="img"
                    height={1}
                    width={1}
                    src={item}
                    alt="..."
                    sx={{
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness() invert(0.7)'
                          : 'none',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Partners;
